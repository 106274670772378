// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import * as firebase from 'firebase';

export const environment = {
  production: false,
  apiPostGrid: 'https://api.postgrid.com/v1/addver',
  refresh_token: 'https://securetoken.googleapis.com/v1',
  apiUrl: 'https://cloudbase-abp.sea-solutions.com/api',
  apiFileUrl: '',
  apiExternalServiceUrl: 'http://localhost:82',
  apiSecurity: '',
  froalaEditorKey: '',
  firebaseConfig: {
    apiKey: "AIzaSyBV-2Mwe5pQ--fE2mlAzLcE7107a_QOc54",
    authDomain: "cloudbase-abp.firebaseapp.com",
    projectId: "cloudbase-abp",
    storageBucket: "cloudbase-abp.appspot.com",
    messagingSenderId: "11020187866",
    appId: "1:11020187866:web:557f714fa594d656230de3",
    measurementId: "G-68HLERJSST"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
